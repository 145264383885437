<template>
  <div id="page-product-plan">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">坯布货号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.greyClothGoodsNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">是否排产</span>
        <Select v-model="listParam.ifScheduling" @on-change="getTableListData" style="width: 120px">
          <Option v-for="item in schedulingStatusList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
      </div>
    </div>

    <!-- 自定义的表格操作栏 -->
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="syncTableData">
        <i class="iconfont iconicon-tongbu"></i>
        同步
      </div>
      <!-- 暂时不删 -->
      <!-- <div class="common-action-item" @click="completeProduction">
        <i class="iconfont iconicon-queren"></i>
        指定完工
      </div> -->
      <div class="common-action-item" @click="cancelProduction">
        <i class="iconfont iconicon-chexiao"></i>
        撤销排产
      </div>
      <div class="common-action-item" @click="exportProductPlanList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>

    <!-- 表格 -->
    <Table
      ref="selection"
      border
      :loading="loading"
      :columns="tableColumns"
      :data="tableData"
      :row-class-name="bindFailRowClass"
      @on-selection-change="selectionChange"
    >
      <template slot-scope="{ row, index }" slot="action">
        <span
          v-if="row.status === 1"
          style="color: #256de6; cursor: pointer"
          @click="clickProductPlan(row, index, 'add')"
          >排产</span
        >
        <span
          v-else
          style="color: #256de6; cursor: pointer"
          @click="clickProductPlan(row, index, 'update')"
          >修改</span
        >
      </template>
      <template slot-scope="{ row }" slot="predictStartDate">
        {{ row.predictStartDate }}
        <Poptip trigger="click" :transfer="true" padding="16px 24px">
          <i
            v-if="checkOvertime(row.predictStartDate)"
            style="color: #f94040"
            class="iconfont iconicon-tishi"
          ></i>
          <span style="color: #f94040" slot="content">当前日期已超过预计开工日期</span>
        </Poptip>
      </template>
      <template slot-scope="{ row }" slot="greyClothSheetNo">
        <span style="color: #256de6; cursor: pointer" @click="goToDetailPage(row)">{{
          row.greyClothSheetNo
        }}</span>
      </template>
    </Table>
    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />

    <!-- 排产安排弹窗 -->
    <Modal
      class="product-plan-modal"
      v-model="isShowProductPlanModal"
      title="排产"
      @on-visible-change="modalVisibleChange"
    >
      <div slot="footer">
        <Button type="text" size="large" @click="isShowProductPlanModal = false">取消</Button>
        <Button type="primary" size="large" @click="submitProductPlan('greyClothPlanOrderFrom')"
          >确定</Button
        >
      </div>

      <Spin size="large" fix v-if="spinShow"></Spin>
      <div class="top">
        <Row style="margin-bottom: 8px">
          <Col span="10"> 坯布指示单号：{{ greyClothPlanOrderInfo.weaveDetail }} </Col>
          <Col span="10" offset="4"> 合同号：{{ greyClothPlanOrderInfo.ztgcpoContractNo }} </Col>
        </Row>
        <Row style="margin-bottom: 8px">
          <Col span="24"> 坯布货号：{{ greyClothPlanOrderInfo.ztgcpoGreyClothItemNo }} </Col>
        </Row>

        <Row style="margin-bottom: 8px">
          <Col span="10"> 客户名称：{{ greyClothPlanOrderInfo.ztgcpoCustomer }} </Col>
          <Col span="10" offset="4"> 客户款号：{{ greyClothPlanOrderInfo.ztgcpoiClientNum }} </Col>
        </Row>
        <Row style="margin-bottom: 8px">
          <Col span="10"> 计划匹重：{{ greyClothPlanOrderInfo.ztgcpoiClothWeight }} </Col>
          <Col span="10" offset="4">
            订单需求数：{{ greyClothPlanOrderInfo.ztgcpoiOrderDemandNum }}
          </Col>
        </Row>
      </div>
      <div class="bottom">
        <Form
          :label-width="120"
          label-position="right"
          ref="greyClothPlanOrderFrom"
          :model="greyClothPlanOrderInfo"
          :rules="greyClothPlanOrderRule"
        >
          <FormItem label="计划匹数" prop="ztgcpoiClothNum"
            ><Input v-model="greyClothPlanOrderInfo.ztgcpoiClothNum"
          /></FormItem>
          <FormItem label="计划数" prop="ztgcpoiPlanNum"
            ><Input v-model="greyClothPlanOrderInfo.ztgcpoiPlanNum"
          /></FormItem>
          <FormItem label="计划下发时间" prop="ztppTransferOrderDate"
            ><el-date-picker
            style="width: 100%"
      v-model="greyClothPlanOrderInfo.ztppTransferOrderDate"
      type="datetime"
      placeholder="选择日期时间"
      default-time="07:30:00"
      @on-change="handleTransferOrderDateChange">
    </el-date-picker>
          </FormItem>
          <FormItem label="预计开工日期" prop="rawPlanStartDate">
            <DatePicker
              style="width: 100%"
              type="date"
              placeholder="请选择日期"
              v-model="greyClothPlanOrderInfo.rawPlanStartDate"
              @on-change="handlePlanStartDateChange"
            ></DatePicker>
          </FormItem>
          <FormItem label="预计完工日期" prop="rawPlanEndDate">
            <DatePicker
              style="width: 100%"
              type="date"
              placeholder="请选择日期"
              v-model="greyClothPlanOrderInfo.rawPlanEndDate"
              @on-change="handlePlanEndDateChange"
            ></DatePicker
          ></FormItem>
          <FormItem label="选择机台" prop="ztppMachineNoList">
            <Tag
              closable
              size="large"
              v-for="item in greyClothPlanOrderInfo.ztppMachineNoList"
              :key="item.machineNo"
              :name="item.machineNo"
              :class="{ 'tag-fail': item.transferOrderStatus == 3 }"
              @on-close="removeMachineTag"
              >{{ item.machineNo }}</Tag
            >
            <Button icon="ios-add-circle-outline" type="text" @click="clickSelectMachine"
              >添加机台</Button
            ></FormItem
          >
          <FormItem label="备注" prop="ztppRemark"
            ><Input type="textarea" v-model="greyClothPlanOrderInfo.ztppRemark"
          /></FormItem>
        </Form>
      </div>
    </Modal>

    <!-- 选择机台弹窗 -->
    <Modal
      class="select-machine-modal"
      :width="860"
      v-model="isShowselectMachineModal"
      title="选择机台"
      @on-ok="submitSelectMachine"
      @on-visible-change="subModalVisibleChange"
    >
      <!-- 机台表格的筛选部分 -->
      <Row style="margin-bottom: 20px" :gutter="20" justify="space-between">
        <Col span="8">
          <Input
            style="min-width: 200px"
            placeholder="通过机台号搜索"
            v-model.trim="machineListParam.param.machineNo"
            @input="getMachineTableListData"
          />
        </Col>
        <Col span="8">
          <Input
            placeholder="通过货号搜索"
            v-model.trim="machineListParam.param.greyClothItemNo"
            @input="getMachineTableListData"
          />
        </Col>
        <Col span="6"
          ><div style="display: flex; align-items: center; white-space: nowrap">
            <span style="padding-right: 10px">状态</span>
            <Select v-model="machineListParam.param.status" @on-change="getMachineTableListData">
              <Option v-for="item in deviceStatusList" :value="item.value" :key="item.value">{{
                item.label
              }}</Option>
            </Select>
          </div>
        </Col>
      </Row>
      <div class="selectedMachineRow">
        已选择机台
        <span style="color: #256de6; margin-left: 10px">{{ selectedDeviceIdList.join("、") }}</span>
      </div>
      <Table
        max-height="480"
        ref="selection"
        :loading="machineLoading"
        :columns="machineTableColumns"
        :data="machineTableData"
        @on-selection-change="machineSelectionChange"
      >
      </Table>
    </Modal>
  </div>
</template>

<script>
import { searchTypeList, schedulingStatusList, deviceStatusList } from "./constant";
import {
  fetchProductPlanList,
  fetchSyncTableData,
  // fetchCompleteProduction,
  fetchCancelProduction,
  fetchTransferOrderDetail,
  fetchDeviceList,
  fetchSubmitProductPlan,
} from "./api";

export default {
  name: "ProductPlan",

  data() {
    return {
       value3: '',
      datePickerOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
      },

      currentRowIndex: 0,

      // 表格列表的请求参数
      listParam: {
        searchType: 1, // 1:坯布指示单号,2:合同号,3:客户名称,4:款式（客户款号）
        searchText: "", // 根据searchType填写相应内容
        machineNo: "", // 机台号
        ifScheduling: -1, // 是否排产，-1全部，0已排产，1未排产

        pageNum: 1,
        pageSize: 10,
      },

      // 首页表格配置
      loading: false,
      tableColumns: [
        {
          type: "selection",
          width: 54,
          align: "center",
        },
        {
          title: "序号",
          type: "index",
          width: 66,
        },
        {
          title: "操作",
          slot: "action",
          width: 70,
        },
         {
          title: "坯布指示单号",
          slot: "greyClothSheetNo",
          key: "greyClothSheetNo",
          width: 140,
        },
          {
          title: "坯布货号",
          key: "greyClothGoodsNo",
          width: 120,
        },
           {
          title: "预计开工日期",
          slot: "predictStartDate",
          key: "predictStartDate",
          width: 140,
        },
         {
          title: "计划下发时间",
          key: "transferOrderDate",
          width: 160,
        },
        {
          title: "状态",
          key: "statusName",
          width: 80,
        },
         {
          title: "计划日期",
          key: "planDate",
          width: 120,
        },
        {
          title: "合同号",
          key: "contractNo",
          width: 140,
        },
        {
          title: "客户款号",
          key: "clientMoneyNo",
          minWidth: 140,
        },
        {
          title: "机台号",
          key: "machineNo",
          width: 80,
        },
        {
          title: "订单需求数",
          key: "orderNeedNum",
          width: 110,
        },
        {
          title: "计划数(kg)",
          key: "ztgcpoiPlanNum",
          width: 110,
        },
        {
          title: "计划匹数",
          key: "ztgcpoiClothNum",
          width: 100,
        },
        {
          title: "计划匹重",
          key: "planPieceWeight",
          width: 100,
        },
        {
          title: "预计完工日期",
          key: "predictFinishDate",
          width: 130,
        },
        {
          title: "交货日期",
          key: "deliveryDate",
          width: 120,
        },
        {
          title: "客户名称",
          key: "clientName",
          minWidth: 160,
        },
        {
          title: "颜色",
          key: "color",
          minWidth: 180,
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 180,
        },
        {
          title: "修改日志",
          key: "modifyLog",
          minWidth: 180,
        },
      ],
      tableData: [],

      listDataTotal: 0, // 分页器数据总条数

      ztppIdList: [], // “纵通” 排产表的id数组

      isShowProductPlanModal: false, // 排产模态框是否显示
      isShowselectMachineModal: false, // 选择机台模态框是否显示

      greyClothPlanOrderInfo: {
        weaveDetail: "", // 坯布指示单号(织造明细)
        ztgcpoContractNo: "", // 合同号
        ztgcpoCustomer: "", // 客户名称
        ztgcpoiClientNum: "", // 客户款号
        ztgcpoGreyClothItemNo: "", // 坯布货号
        ztgcpoiClothWeight: 0, // 计划匹重
        ztgcpoiOrderDemandNum: 0, // 订单需求数
        ztgcpoiPlanNum: 0, // 计划数
        ztgcpoiClothNum: 0, // 计划匹数

        ztppTransferOrderDate: this.moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // 自动下发时间，形如 "2021-12-11 12:14:11"
        ztppPlanStartDate: "", // 预计开工日期，形如 "2021-12-11"
        ztppPlanEndDate: "", // 预计完工日期

        // ————————————————我是分割线——————————————

        // 纯前端用的数据源，与表单的交互组件依次绑定
        rawTransferOrderDate: null, // 对应 ztppTransferOrderDate
        rawPlanStartDate: null, // 对应 ztppPlanStartDate
        rawPlanEndDate: null, // 对应 ztppPlanEndDate

        // ————————————————我是分割线——————————————

        ztppMachineNoList: [
          {
            machineNo: "0#",
            transferOrderStatus: 3, // 3表示下发失败
          },
        ],

        ztppRemark: "", // 备注

        ztppId: 0, // 排产表id
        ztppPlanNum: 0, // 计划数(来自排产表) // 这2个字段先留着，后续可能会用到或者修改
        ztppClothNum: 0, // 计划匹数(来自排产表)
      },
      greyClothPlanOrderRule: {
        ztgcpoiClothNum: [
          {
            required: true,
            message: "计划匹数不能为空",
          },
        ],
        ztgcpoiPlanNum: [
          {
            required: true,
            message: "计划数不能为空",
          },
        ],
        ztppPlanStartDate: [
          {
            type: "date",
            required: true,
            message: "预计开工日期不能为空",
            trigger: "change",
          },
        ],
        ztppPlanEndDate: [
          {
            type: "date",
            required: true,
            message: "预计完工日期不能为空",
            trigger: "change",
          },
        ],
        ztppMachineNoList: [
          {
            type: "array",
            required: true,
            message: "选择机台不能为空",
            validator: (rule, value) => value.length > 0,
          },
        ],
      },

      spinShow: false, // 排产弹窗获取数据时的加载状态

      // 机台弹窗的表格配置
      machineListParam: {
        param: {
          status: -1, // 状态（-1 全部，1 空闲， 2 在产）
          machineNo: "",
          greyClothItemNo: "",
        },
      },

      machineLoading: false,
      machineTableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 120,
        },
        {
          title: "设备类型",
          key: "deviceType",
          minWidth: 120,
        },
        {
          title: "状态",
          key: "status",
          minWidth: 120,
        },
        {
          title: "坯布货号",
          key: "greyClothItemNo",
          minWidth: 120,
        },
        {
          title: "客户款号",
          key: "clientNum",
          minWidth: 120,
        },
        {
          title: "预计完工日期",
          key: "maxDate",
          minWidth: 120,
        },
      ],
      machineTableData: [],

      previousDeviceIdList: [], // 打开机台弹窗前，已有机台编号的数组
      selectedDeviceIdList: [], // 在机台弹窗内操作，最终所有已选择的机台编号的数组(包括 previousDeviceIdList)

      searchTypeList, // 首页——搜索类型的 Select 下拉框列表
      schedulingStatusList, // 首页——是否排产的 Select 下拉框列表
      deviceStatusList, // 选择机台弹窗——设备状态的 Select 下拉框列表
    };
  },

  created() {
    this.getTableListData();
  },
  methods: {
    handleTransferOrderDateChange(formatDate, DateObj) {
      this.greyClothPlanOrderInfo.ztppTransferOrderDate = formatDate;
    },
    handlePlanStartDateChange(formatDate, DateObj) {
      this.greyClothPlanOrderInfo.ztppPlanStartDate = formatDate;
    },
    handlePlanEndDateChange(formatDate, DateObj) {
      this.greyClothPlanOrderInfo.ztppPlanEndDate = formatDate;
    },

    getTableListData() {
      this.loading = true;
      fetchProductPlanList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            // 表格中相应数据行设为多选禁用状态，（需禁用 1：待排产，3：量产中，4：指定完工）
            for (let i = 0; i < list.list.length; i++) {
              const { status } = list.list[i];
              if (status === 1 || status === 3 || status === 4) {
                list.list[i]._disabled = true;
              }
            }

            this.tableData = list.list;
            this.listDataTotal = list.total;

            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMachineTableListData() {
      this.machineLoading = true;
      fetchDeviceList(this.machineListParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { deviceList } = res.data.body;

            for (let i = 0; i < deviceList.length; i++) {
              for (let j = 0; j < this.previousDeviceIdList.length; j++) {
                if (deviceList[i].machineNo === this.previousDeviceIdList[j]) {
                  deviceList[i]._checked = true; // 默认加上之前已选中项的复选框
                  deviceList[i]._disabled = true; // 设置之前已选中项的复选框为禁用
                }
              }
            }

            this.machineTableData = deviceList;
          } else {
            this.$Message.error(res.data.msg);
          }
          this.machineLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    // 同步表格数据
    syncTableData() {
      this.loading = true;
      fetchSyncTableData()
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableListData();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 撤销排产
    cancelProduction() {
      if (this.ztppIdList.length <= 0) {
        this.$Message.warning("请先选择相应的排产计划");
        return;
      }

      fetchCancelProduction(this.ztppIdList)
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("撤销排产操作成功！");
            this.getTableListData();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 导出表格
    exportProductPlanList() {
      const { pageNum, pageSize, ...data } = this.listParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/ProductPlanController/productPlanExport?${dataStr}`;
    },

    selectionChange(selection) {
      this.ztppIdList = selection.map((item) => item.ztppId);
    },
    machineSelectionChange(selection) {
      // 先合并，再去重
      this.selectedDeviceIdList = Array.from(
        new Set([...this.previousDeviceIdList, ...selection.map((item) => item.machineNo)])
      );
    },

    modalVisibleChange(isVisible) {
      this.$refs.greyClothPlanOrderFrom.resetFields();
    },

    subModalVisibleChange(isVisible) {
      if (!isVisible) {
        this.machineListParam = {
          param: {
            status: -1, // 状态（-1 全部，1 空闲， 2 在产）
            machineNo: "",
            greyClothItemNo: "",
          },
        };
      } else {
        this.previousDeviceIdList = this.greyClothPlanOrderInfo.ztppMachineNoList.map(
          (item) => item.machineNo
        );

        this.selectedDeviceIdList = [...this.previousDeviceIdList];
      }
    },

    // 跳转到对应的坯布指示单坯布生产查询详情页面
    goToDetailPage({ greyClothSheetNo }) {
      this.$router.push({
        path: "/workOrderManage/clothSheetDetail",
        query: { ztgcpoiWeaveDetail: greyClothSheetNo },
      });
    },

    // 排产弹窗的相关事件
    clickProductPlan({ ztgcpoiId, ztppId }, index, action) {
      this.currentRowIndex = index;
      this.isShowProductPlanModal = true;
      this.spinShow = true;

      fetchTransferOrderDetail({ ztgcpoiId, ztppId })
        .then((res) => {
          if (res.data.success === 1) {
            const { greyClothPlanOrderInfo } = res.data.body;
            const { ztppTransferOrderDate, ztppPlanStartDate, ztppPlanEndDate } =
              greyClothPlanOrderInfo;

            if (action === "update") {
              this.greyClothPlanOrderInfo = {
                ...greyClothPlanOrderInfo,
                rawTransferOrderDate: ztppTransferOrderDate,
                rawPlanStartDate: ztppPlanStartDate,
                rawPlanEndDate: ztppPlanEndDate,
              };
            } else {
              this.greyClothPlanOrderInfo = {
                ...greyClothPlanOrderInfo,
                rawTransferOrderDate: null,
                rawPlanStartDate: null,
                rawPlanEndDate: null,
              };
            }
          } else {
            this.$Message.error(res.data.msg);
          }
          this.spinShow = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clickSelectMachine() {
      this.isShowselectMachineModal = true;
      this.getMachineTableListData();
    },

    removeMachineTag(event, name) {
      const index = this.greyClothPlanOrderInfo.ztppMachineNoList
        .map((item) => item.machineNo)
        .indexOf(name);
      this.greyClothPlanOrderInfo.ztppMachineNoList.splice(index, 1);
    },

    // 确定当前所选的机台编号，添加到总的设备编号列表中
    submitSelectMachine() {
      this.greyClothPlanOrderInfo.ztppMachineNoList = this.selectedDeviceIdList.map((item) => ({
        machineNo: item,
        transferOrderStatus: 1, // 待下发，全这样处理，先不管
      }));

      // 暂时先注释，后面在说
      // for (let i = 0; i < this.previousDeviceIdList.length; i++) {
      //   const element = this.previousDeviceIdList[i];
      //   let isExist = false;
      //   this.greyClothPlanOrderInfo.ztppMachineNoList.forEach((item) => {
      //     if (item.machineNo === element) isExist = true;
      //   });

      //   if (!isExist) {
      //     this.greyClothPlanOrderInfo.ztppMachineNoList.push({
      //       machineNo: element,
      //     });
      //   }
      // }
    },

    submitProductPlan(formRef) {
      const {
        ztppMachineNoList: machines,
        ztppTransferOrderDate,
        ztppPlanStartDate,
        ztppPlanEndDate,
        ...otherParam
      } = this.greyClothPlanOrderInfo;
      const {
        ztppId,
        ztgcpoiPlanNum, // 计划数
        ztgcpoiClothNum, // 计划匹数
        ztppRemark,
      } = otherParam;

      this.$refs[formRef].validate((valid) => {
        if (valid) {
          if (
            ztppTransferOrderDate &&
            this.moment(ztppTransferOrderDate) <= this.moment(new Date())
          ) {
            // 计划下发时间(可选填)，若填写了，进行校验————只能填当前时间之后的时间
            this.$Message.warning("计划下发时间填写不规范，只能填写当前时间之后的时间日期！");
            return;
          }

          if (
            ztppPlanStartDate &&
            ztppPlanEndDate &&
            this.moment(ztppPlanEndDate) < this.moment(ztppPlanStartDate)
          ) {
            this.$Message.warning("预计完工日期不能早于预计开工日期！");
            return;
          }

          const ztppMachineNoList = machines.map((item) => item.machineNo).join(",");
          fetchSubmitProductPlan({
            ztppId, // 排产表id
            ztppZtgcpoiId: this.tableData[this.currentRowIndex].ztgcpoiId, // 坯布指示单详情表ID
            ztppPlanNum: ztgcpoiPlanNum, // 计划数
            ztppClothNum: ztgcpoiClothNum, // 计划匹数
            ztppTransferOrderDate, // (自动)下发时间
            ztppPlanStartDate, // 预计开工日期
            ztppPlanEndDate, // 预计完工日期
            ztppMachineNoList, // 机台号列表  形如 字符串 "1,3,4,5,6"
            ztppRemark, // 备注
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.$Message.success("提交排产计划成功！");
                this.getTableListData();
                this.isShowProductPlanModal = false;
              } else {
                this.$Message.error(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$Message.warning("请填写完整的表单数据！");
        }
      });
    },

    bindFailRowClass(row, index) {
      // 本页面用不到，暂时注释该方法
      // // 下发失败，有机台未下发成功，为表格中相应的行，添加背景颜色
      // const { transferOrderStatus } = row;
      // if (transferOrderStatus === 1) {
      //   return "row-status-fail";
      // }
    },

    checkOvertime(date) {
      // date 时间字符串格式 "2021-07-21"
      if (!date) return false;

      const currentDate = new Date().setHours(0, 0, 0, 0);
      const planDate = new Date(date).setHours(0, 0, 0, 0);

      // 同一天不算超时
      if (currentDate > planDate) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

// 表格下面的分页器部分
.page {
  margin-top: 10px;
  text-align: center;
}

// 排产模态框
.modal-container-product-plan {
  .top {
    margin-bottom: 20px;
  }
}

// 机台模态框
.select-machine-modal {
  .selectedMachineRow {
    background-color: #f9f9f9;
    margin: 20px 0;
    padding: 10px;
  }
}
</style>


<style lang="scss">
.product-plan-modal {
  .ivu-tag {
    background-color: #fff;
    border: 1px #57a3f3 solid;
  }

  .tag-fail {
    background: rgba(249, 64, 64, 0.3);
    border: 1px #f94040 solid;
  }
}

#page-product-plan {
  .ivu-table .row-status-fail td {
    background-color: rgba(249, 64, 64, 0.1);
  }
}
</style>>

