// 搜索类型的 Select 下拉框列表
 const searchTypeList = [
  {
    value: 1,
    label: "坯布指示单号",
  },
  {
    value: 2,
    label: "合同号",
  },
  {
    value: 3,
    label: "客户名称",
  },
  {
    value: 4,
    label: "客户款号",
  },
];
// 排产状态的 Select 下拉框列表
 const schedulingStatusList = [
  {
    value: -1,
    label: "全部",
  },
  {
    value: 0,
    label: "已排产",
  },
  {
    value: 1,
    label: "待排产",
  },
];


// 机台设备状态的 Select 下拉框列表
const deviceStatusList = [
  {
    value: -1,
    label: "全部",
  },
  {
    value: 1,
    label: "空闲",
  },
  {
    value: 2,
    label: "在产",
  },
];


export {
  searchTypeList,
  schedulingStatusList,
  deviceStatusList
}
