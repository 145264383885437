import axios from "axios";

/**
 * 获取排产计划列表
 */
export const fetchProductPlanList = params => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/productPlanHomePage",
    method: "GET",
    params
  });
};

/**
 * 同步数据
 */
export const fetchSyncTableData = () => {
  return axios({
    url: "/dtsum/zongtong/basic/CommonController/synTable",
    method: "GET"
  });
};

/**
 * 指定选中的 “已排产” 的坯布指示单子，变成完成状态
 */
export const fetchCompleteProduction = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/endMassProductionOfPlanOrder",
    method: "POST",
    data
  });
};

/**
 * 撤销选中的 “已排产” 的坯布指示单子，变成待排产状态
 */
export const fetchCancelProduction = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/revokeProductPlan",
    method: "POST",
    data
  });
};

/**
 * 获取坯布指示单信息，排产弹窗中展示
 */
export const fetchTransferOrderDetail = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/greyClothPlanOrderInfo",
    method: "GET",
    params: { param: paramObj }
  });
};

/**
 * 获取生产机台列表，机台弹窗中展示
 */
export const fetchDeviceList = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/deviceList",
    method: "GET",
    params: paramObj
  });
};

/**
 * 提交排产计划
 */
export const fetchSubmitProductPlan = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/submitProductPlan",
    method: "POST",
    data
  });
};
